import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaGithub } from "react-icons/fa";

let data = [
    {
        images: "01",
        title: "Youssef Hlem",
        designation: "Sr. Frontend Engineer",
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaGithub />,
                url: "#",
            },
        ],
    },
    {
        images: "03",
        title: "Saif Rashad",
        designation: "Front-end Engineer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
        ],
    },
    {
        images: "07",
        title: "Omer Rashad",
        designation: "Software Engineer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "06",
        title: "Mohamed Radwan",
        designation: "Owner & Software Engineer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "05",
        title: "Fatima Asrafi",
        designation: "Front-end Engineer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
        ],
    },
    {
        images: "06",
        title: "Fatima Amar",
        designation: "Sr. Graphic Designer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "07",
        title: "Fatima Amar",
        designation: "Sr. Graphic Designer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "11",
        title: "John Dou",
        designation: "Sr. Graphic Designer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "09",
        title: "John Dou",
        designation: "Sr. Graphic Designer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
    {
        images: "11",
        title: "John Dou",
        designation: "Sr. Graphic Designer",
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: "#",
            },
            {
                icon: <FaLinkedinIn />,
                url: "#",
            },
            {
                icon: <FaTwitter />,
                url: "#",
            },
        ],
    },
];

export default data;
