import React from "react";
import { ProgressBar } from "react-bootstrap";
import Helmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import {
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiTablet,
  FiLayout,
} from "react-icons/fi";
import Header from "../component/header/Header";
import Team from "../blocks/team/Team";
import Footer from "../component/footer/Footer";
import Brand from "../elements/Brand";

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--33",
    category: "We are Agency",
    title: "Technology & innovation for Agency services.",
    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

const ServiceList = [
  {
    id: 1,
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "Creating visually stunning and functional websites tailored to your needs.",
  },
  {
    id: 2,
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "Building feature-rich mobile applications for your unique requirements.",
  },
  {
    id: 3,
    icon: <FiTablet />,
    title: "Cross Platform Development",
    description:
      "Developing versatile cross-platform solutions for wider reach and efficiency.",
  },
  {
    id: 4,
    icon: <FiUsers />,
    title: "Hosting",
    description:
      "Reliable hosting services for seamless online presence and data management.",
  },
  {
    id: 5,
    icon: <FiLayout />,
    title: "Designing",
    description:
      "Creating captivating designs to enhance your brand and user experience.",
  },
];

const InteractiveAgency = () => {
  return (
    <>
      <Helmet pageTitle="Interactive Agency" />
      <Header />

      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        <div className="slider-activation">
          {SlideList.map((value, index) => (
            <div
              className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
              key={index}
              data-black-overlay="8"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className="title">{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-solid"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">What do we do?</h2>
                    <p className="description">
                      softigital is an organization that specializes in
                      developing, implementing, and maintaining software
                      products and solutions. They have teams of software
                      engineers, developers, designers, testers, and project
                      managers who work together to create software
                      applications, systems, or services. These companies offer
                      services such as software development, custom application
                      development, web and mobile app development, software
                      testing, maintenance and support, consulting, and
                      integration. They may also develop and sell their own
                      software products. Software companies work with various
                      technologies, follow agile methodologies, and prioritize
                      quality assurance and testing. They serve clients across
                      industries and provide ongoing support and maintenance.
                    </p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Working Process.</h3>
                        <div className="rn-progress-bar progress-bar--3">
                          <div className="single-progress custom-color--1">
                            <h6 className="title">Developing With React</h6>
                            <ProgressBar now={94} />
                            <span className="label">94%</span>
                          </div>

                          <div className="single-progress custom-color--2">
                            <h6 className="title">Managment</h6>
                            <ProgressBar now={72} />
                            <span className="label">72%</span>
                          </div>

                          <div className="single-progress custom-color--3">
                            <h6 className="title">Hosting</h6>
                            <ProgressBar now={89} />
                            <span className="label">89%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/softigital/businessman-pointing-his-business-presentation-high-technology-digital-screen.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Service Area */}
      <div className="service-area creative-service-wrapper pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>Our Service</h2>
                {/* <p>
                  There are many variations of passages of Lorem Ipsum
                  available, <br /> but the majority have suffered alteration.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row creative-service">
            {ServiceList.map((val, i) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
              >
                <a className="text-center" href={`/service-details/${val.id}`}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Service Area */}

      {/* Start Team Area  */}
      <div className="rn-team-wrapper ptb--120 bg_color--5">
        <div className="rn-team-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Our Skilled Team</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <Team column="col-lg-3" teamStyle="" item="4" />
          </div>
        </div>
      </div>
      {/* End Team Area  */}

      {/* Start Brand Area  */}
      <div className="rn-brand-area pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Brand />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </>
  );
};

export default InteractiveAgency;
