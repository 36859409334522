import React from "react";
import PageHelmet from "../component/common/Helmet";
// import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import {  portfolioSlick2 } from "../page-demo/script";

const PortfolioList2 = [
    {
        id: 1,
        image: "image-1",
        category: "Development",
        title: " Getting tickets to the big show",
    },
    {
        id: 2,
        image: "image-2",
        category: "Development",
        title: " Getting tickets to the big show",
    },
    {
        id: 3,
        image: "image-3",
        category: "Development",
        title: " Getting tickets to the big show",
    },
    {
        id: 4,
        image: "image-4",
        category: "Development",
        title: " Getting tickets to the big show",
    },
    {
        id: 5,
        image: "image-3",
        category: "Development",
        title: " Getting tickets to the big show",
    },
    {
        id: 6,
        image: "image-4",
        category: "Development",
        title: " Getting tickets to the big show",
    },
];

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle="Counters" />

            {/* Start Header Area  */}
            <Header
                headertransparent="header--transparent"
                colorblack="color--black"
                logoname="logo.png"
            />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div
                className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
                data-black-overlay="6"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">
                                    Our Portfolio
                                </h2>
                                <p>
                                    We take businesses to the next level with
                                    software solutions we build.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">All Works</h2>
                                    <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--70 mt_sm--40">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value, index) => (
                                    <div className="portfolio" key={index}>
                                        <div className="thumbnail-inner">
                                            <div
                                                className={`thumbnail ${value.image}`}
                                            ></div>
                                            <div
                                                className={`bg-blr-image ${value.image}`}
                                            ></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4>
                                                    <a
                                                        href={`/portfolio-details/${value.id}`}
                                                    >
                                                        {value.title}
                                                    </a>
                                                </h4>
                                                <div className="portfolio-button">
                                                    <a
                                                        className="rn-btn"
                                                        href="/portfolio-details"
                                                    >
                                                        Case Study
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">All Works</h2>
                                        <p>
                                            There are many variations of
                                            passages of Lorem Ipsum available,
                                            but the majority have suffered
                                            alteration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList
                                    styevariation="text-left mt--40"
                                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                                    item="6"
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
    );
};

export default Portfolio;
