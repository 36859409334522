import React, { Component } from "react";

class Brand extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/cscLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img
              src="/assets/images/brand/infiniteLogo.svg"
              alt="Logo Images"
            />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/sableLogo.png" alt="Logo Images" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default Brand;
