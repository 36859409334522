import React, { Component } from "react";
import { Link } from "react-router-dom";

const PortfolioListContent = [
  {
    id: 1,
    image: "image-1",
    category: "Development",
    title: "Sable",
    description:
      "Sable system includes a dashboard with user roles for admin, restaurant, marketer, logistic, finance, and operation. There is also a separate customer portal. Users can manage menus, orders, promotions, deliveries, finances, and system settings. Customers can browse menus, customize orders, make payments, and track deliveries. The system ensures efficient management and a seamless ordering experience.",
    descImage: "/assets/images/portfolio/details/sableImage.png",
  },
  {
    id: 2,
    image: "image-2",
    category: "Development",
    title: "El Saleh",
    description:
      "El Saleh system has a Dashboard Portal for User and project management, donation, volunteer tracking, and communication for admin staff. Website Portal: Project listings, donation options,  news, and contact/support for public users.Integration between the two portals for data synchronization.Admins manage user accounts, projects, donations, and volunteers through the dashboard portal. Public users access project information, make donations, sign up for volunteer work, and get updates through the website portal.The system aims to streamline charity operations, engage supporters, and promote the organization's mission.",
  },
  {
    id: 3,
    image: "image-3",
    category: "Development",
    title: "Remote Work",
    description:
      "The Remote Work website connects freelancers and clients seeking services. Freelancers create profiles and submit proposals for client projects. Clients evaluate proposals, communicate with freelancers, and hire them. Projects are executed through the works website, with tools for file sharing, messaging, and progress tracking.Payment processing is handled securely through the works website.Feedback and ratings are exchanged between freelancers and clients after project completion.Works websites provide ongoing support and dispute resolution for a seamless freelance experience.",
  },
  {
    id: 4,
    image: "image-4",
    category: "Development",
    title: "CSC",
    description:
      "Protecting from digital and physical risks involves implementing measures such as cybersecurity, data protection, access controls, surveillance systems, employee training, risk assessments, and compliance. Continuous monitoring and improvement are necessary to safeguard assets, data, and operations.",
    descImage: "/assets/images/portfolio/details/cscImage.png",
  },
  {
    id: 5,
    image: "image-3",
    category: "Development",
    title: "Infinite",
    description:
      "The recruitment industry connects employers with candidates for job positions. It involves advertising job vacancies, sourcing candidates, conducting interviews, and making placements. Agencies specialize in specific industries or sectors and offer both temporary and permanent placements. They help organizations acquire top talent and provide career guidance to job seekers. The industry is influenced by trends such as AI and automation. Some companies outsource their recruitment process to specialized agencies. Ethical standards are followed to ensure fair practices.",
    descImage: "/assets/images/portfolio/details/infiniteImage.png",
  },
  {
    id: 6,
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <Link
                      to={{
                        pathname: `/portfolio-details/${value.id}`,
                        state: {
                          id: value.id,
                          title: value.title,
                          description: value.description,
                          descImage: value.descImage,
                        },
                      }}
                    >
                      {value.title}
                    </Link>
                  </h4>
                  <div className="portfolio-button">
                    <Link
                      className="rn-btn"
                      to={{
                        pathname: `/portfolio-details/${value.id}`,
                        state: {
                          id: value.id,
                          title: value.title,
                          description: value.description,
                          descImage: value.descImage,
                        },
                      }}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
              <Link className="link-overlay" to="/portfolio-details"></Link>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
