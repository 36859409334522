import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { withRouter } from "react-router-dom";

const data = [
  {
    id: 1,
    title: "Business Strategy",
    content1:
      "Our business strategy services are the cornerstone of success. We analyze markets, evaluate your business, and devise tailored strategies.",
    content2:
      "Our strategies are data-driven, shaped by comprehensive research and actionable planning for your long-term vision.",
    content3: {
      one: "Market Research",
      two: "Competitive Analysis",
      three: "SWOT Analysis",
      four: "Strategic Planning",
      five: "Goal Setting",
      six: "Risk Assessment",
    },
    content4:
      "We believe in data-driven decision-making. Our strategies are shaped by comprehensive market research, providing insights into customer preferences and emerging trends.",
    content5:
      "Our strategic planning includes a roadmap for your business to ensure a clear path to success.",
    content6: {
      one: "Market Trends",
      two: "Target Audience",
      three: "Performance Metrics",
    },
  },
  {
    id: 2,
    title: "Website Development",
    content1:
      "Our website development services are tailored for your digital needs, exceeding expectations. We ensure meticulous design and optimal user experiences.",
    content2:
      "We blend exceptional design with modern principles for a competitive online presence.",
    content3: {
      one: "User-Centric Interface Design",
      two: "Optimized Performance for Speed",
      three: "Scalable Architecture for Growth",
      four: "Seamless E-commerce Integration",
      five: "Robust Content Management Systems",
      six: "Cross-Browser Compatibility",
    },
    content4:
      "We're committed to your success with fast, secure, and adaptable websites. Future-proof your online presence with us.",
    content5:
      "Our websites ensure speed, security, and adaptability, staying relevant in the ever-evolving digital landscape.",
    content6: {
      one: "Responsive Design for All Devices",
      two: "Effective Search Engine Optimization",
      three: "Immersive and Interactive User Experience",
    },
  },
  {
    id: 3,
    title: "Mobile App Development",
    content1:
      "Our mobile app development is dedicated to creating innovative solutions. We ensure meticulous design and superior user experiences.",
    content2:
      "We merge advanced functionality with modern design, captivate your audience, and remain competitive in the mobile landscape.",
    content3: {
      one: "User-Centric Interface Design",
      two: "Optimized Performance for Speed",
      three: "Scalable Architecture for Growth",
      four: "Seamless Integration of Features",
      five: "Robust Data Management Systems",
      six: "Cross-Platform Compatibility",
    },
    content4:
      "We empower your app with speed, security, and adaptability, catering to evolving user requirements and staying competitive.",
    content5:
      "Our apps are fast, secure, and adapt to technological advancements and user preferences, ensuring competitiveness in the mobile industry.",
    content6: {
      one: "Cross-Platform Compatibility",
      two: "Effective User Engagement Strategies",
      three: "Seamless Integration of Emerging Technologies",
    },
  },
  {
    id: 4,
    title: "Cross-Platform Development",
    content1:
      "Our cross-platform development services are dedicated to versatile, high-performance digital solutions. Seamless user experiences across platforms is our focus.",
    content2:
      "We create unified and engaging experiences for various devices and platforms, maintaining quality and functionality.",
    content3: {
      one: "User-Centric Interface Design",
      two: "Optimized Performance for Speed",
      three: "Scalable Architecture for Growth",
      four: "Seamless Integration on Multiple Platforms",
      five: "Efficient Data Management Systems",
      six: "Consistent User Experience Across Devices",
    },
    content4:
      "Our cross-platform development offers a seamless user experience, reducing costs while delivering consistent quality.",
    content5:
      "Consistency, performance, and cost-efficiency are the cornerstones of our cross-platform development.",
    content6: {
      one: "Unified User Experience Across Platforms",
      two: "Cost-Effective App Maintenance",
      three: "Scalability for Future Growth",
    },
  },
  {
    id: 5,
    title: "Hosting",
    content1:
      "Our hosting services ensure a reliable and secure online presence, the foundation of your digital identity.",
    content2:
      "We deliver fast, secure data management with state-of-the-art data centers, regular backups, and 24/7 monitoring.",
    content3: {
      one: "High Uptime and Availability",
      two: "Scalable Hosting Solutions",
      three: "Data Security and Privacy",
      four: "Automatic Backups and Disaster Recovery",
      five: "24/7 Monitoring and Support",
      six: "Custom Hosting Plans to Suit Your Needs",
    },
    content4:
      "We empower you with high uptime, scalability, and robust security, ensuring your digital assets are accessible and protected.",
    content5:
      "Reliability, performance, and security are the cornerstones of our hosting solutions, keeping your online presence available and protected.",
    content6: {
      one: "High Performance and Speed",
      two: "Scalable Infrastructure for Growth",
      three: "Data Security and Compliance",
    },
  },
  {
    id: 6,
    title: "Designing",
    content1:
      "Our designing services create captivating, user-centric visual solutions, enhancing user experiences and brand success.",
    content2:
      "We craft visual experiences that captivate, resonate with your audience, and stand out in the digital landscape.",
    content3: {
      one: "Creative Visual Design",
      two: "User-Centric Interface Design",
      three: "Seamless User Interaction",
      four: "Branding and Identity Design",
      five: "Responsive and Mobile-First Design",
      six: "Print and Digital Design Services",
    },
    content4:
      "We're dedicated to enhancing your brand, inspiring trust, and improving user experiences through our captivating designs.",
    content5:
      "Captivation, user engagement, and brand elevation are the cornerstones of our designing services, keeping your design competitive.",
    content6: {
      one: "Striking Visual Creativity",
      two: "Brand Enhancement and Recognition",
      three: "User-Centric and Mobile-Optimized Design",
    },
  },
];

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    const { id } = this.props.match.params;
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">{data[id].title}</h2>
                  <p>Fresh From The Press Discription</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/softigital/3d-rendering-website-hosting-concept.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>{data[id].content1}</p>
                          <p>{data[id].content2}</p>
                          <h4 className="title">Proceess of metel</h4>
                          <ul className="liststyle">
                            {Object.values(data[id].content3).map(
                              (item, index) => (
                                <li key={index}>{item}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>{data[id].content4}</p>
                          <p>{data[id].content5}</p>
                          <h4 className="title">Our Working Process</h4>
                          <ul className="liststyle">
                            {Object.values(data[id].content6).map((list) => (
                              <li key={list}>{list}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/softigital/Jan42.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withRouter(ServiceDetails);
